import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./pel.module.scss"
import Img from "gatsby-image"
import Cookies from 'universal-cookie'
import ReactWOW from "react-wow/"

export default ({data}) => {
    const cookies = new Cookies();
    var visited = cookies.get('visited');
    if (typeof visited === 'undefined') {
        cookies.set('visited', true, {path: '/'});
    }
    return (
        <Layout>
            <SEO title="Armas Peligrosas | Aerolineas Ejecutivas"/>

            <section className={style.pelHeader}>
                <Img className={style.img} fluid={data.portada.childImageSharp.fluid}/>
                {/*<PlayerHome/>*/}
            </section>


            <ReactWOW animation='fadeIn'>

                <section className={style.pelSubtitle}>
                    <h1>Arpel (Armas Peligrosas)</h1>
                </section>

            </ReactWOW>

            <ReactWOW>
                <section className={style.pelText}>
                    <p>
                        Estimado pasajero, <br/><br/>
                        El presente es un amable recordatorio por medio del cual compartimos con usted el procedimiento
                        a realizar cuando uno o varios pasajeros viajan con ARPEL (arma peligrosa), así como el
                        brindarles la información acerca de las Mercancías Peligrosas que no se pueden transportar a
                        bordo de las aeronaves de Aerolíneas Ejecutivas.
                    </p>
                    <p><b>PROCEDIMIENTO PARA ARPEL:</b></p>
                    <ul>
                        <li><p>
                            El agente(s) de la Guardia Nacional hará la revisión pertinente, la cual incluye verificar
                            físicamente el arma, la documentación que acredite su propiedad y manejo. En algunas
                            ocasiones el proceso puede ser en la oficina de la Guardia Nacional.
                        </p>
                        </li>
                        <li><p>
                            El portador debe presentar identificación oficial vigente, así como el permiso de portación
                            de armas.</p>
                        </li>
                        <li><p>
                            El oficial realizará el llenado del formato necesario y realizada la revisión, se autorizará
                            el abordaje.</p>
                        </li>
                        <li><p>El procedimiento puede tardar en promedio 30 min., por lo que se solicita arribar con el
                            tiempo suficiente de antelación y en caso de demora informar oportunamente por favor.
                        </p></li>
                    </ul>
                    <p>
                        *El mismo procedimiento se debe llevar a cabo en cada uno de los Aeropuertos que se visiten,
                        dependiendo de la estación la Guardia Nacional puede determinar que el procedimiento se realice
                        directamente en las oficinas, es decir no se trasladan hacia el FBO en caso de existir o a
                        Plataforma General, en su caso.
                        Cabe señalar que, en algunos Aeropuertos, el procedimiento puede incluir también la revisión por
                        parte de SEDENA.
                    </p>
                    <p>
                        Buscando cuidar al máximo su comodidad y los tiempos de su importante agenda, le agradeceremos
                        de
                        sobremanera que nos informe anticipadamente si viajará con ARPEL para así poder avisar a las
                        autoridades correspondientes y tratar de agilizar los procesos de revisión.
                    </p>
                    <p>
                        Sin más que agregar por el momento, esperamos esta información sea de
                        utilidad para sus futuros vuelos y quedamos como siempre a sus órdenes para
                        cualquier duda o comentario.
                    </p>
                    <p>Saludos cordiales.</p>

                </section>
                <section className={style.important}>
                    <div className={style.left}>
                        <Img className={style.img} fluid={data.warning.childImageSharp.fluid}/>

                    </div>
                    <div className={style.right}>
                        <p><b>COMUNICADO IMPORTANTE</b></p>
                        <p>Estimados clientes,</p>
                        <p>Se les informa, que por ley, toda persona abordo de la aeronave deberá ser previamente
                            identificada,
                            por lo que se les solicita de la manera más atenta contar con identificación oficial en todo
                            momento.</p>
                    </div>

                </section>
            </ReactWOW>

        </Layout>
    )
}


export const query = graphql`
    query ImagesArpel {
    portada: file(relativePath: {eq: "pel/arpel.jpg"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 1920) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
    warning: file(relativePath: {eq: "pel/warning.png"}) {
    publicURL
        childImageSharp {
            fluid(maxWidth: 400) {      
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    
}`

